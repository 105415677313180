@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syncopate:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rokkitt:ital,wght@0,100..900;1,100..900&family=Syncopate:wght@400;700&display=swap');

:root {
  --brand-font: "Syncopate", sans-serif;
  --primary-font: "Jost", sans-serif; 
  --heading-font: "Jost", sans-serif; 
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: var(--primary-font);
}
body #root > .ant-layout {
  min-height: 100vh;
}

/* Scroll-Bar */
* { scroll-behavior: smooth; }
::-webkit-scrollbar { width: 6px; }
::-webkit-scrollbar-track { background: #f1f1f1; } 
::-webkit-scrollbar-thumb { background: #888; border-radius: 10px; }
::-webkit-scrollbar-thumb:hover { background: #555; }

.logo {
  color: #fff;
  margin: 20px 10px;
  text-transform: uppercase;
  letter-spacing: 10px;
  font-size: 26px;
  font-weight: 600;
  font-family: var(--heading-font);
  text-align: center;
}
.logo.dark { color: #252525; }

img {max-width: 100%}
.logo img { width: 150px; }

/* General */
.ant-layout-header svg { width: 16px; height: 16px;}
.ant-menu svg { width: 20px; height: 20px; }
.ant-breadcrumb .ant-breadcrumb-link:hover {background-color: transparent;}
.custom-separator { display: block; width: 4px; height: 4px; border-radius: 50%; background-color: rgb(160, 160, 160); transform: translateY(8px); }
.content-bx { margin-top: 24px; }
.content-card:not(:last-child) { margin-bottom: 24px; }
.content-bx,
.content-card,
.table { box-shadow: 0 5px 20px rgba(173,181,217,.05) }

.table {margin-top: 10px;}
.table .name { text-transform: capitalize; }
.table .desc { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; }
.table .links svg { height: 20px; width: 20px; }
.table-responsive{max-width: 100%; overflow-x: auto; background-color: #fff;}
.table-responsive::-webkit-scrollbar {height: 10px; border-radius: 5px;}
.table-responsive::-webkit-scrollbar-track { background: #f1f1f1; border-radius: 5px; }
.table-responsive::-webkit-scrollbar-thumb { background: #888; border-radius: 5px; }
.table-responsive::-webkit-scrollbar-thumb:hover { background: #555; }

/* Admin - LayoutSide Menu */
.ant-layout .side-menu { background-color: #000; position: fixed; top: 0; bottom: 0; --width: 280px; min-width: var(--width) !important; width: var(--width) !important; max-width: var(--width) !important; z-index: 99; }
.ant-layout .side-menu  + .ant-layout{ --width: 280px; margin-left: var(--width); }
.ant-layout .side-menu .ant-layout-sider-zero-width-trigger { top: 12px; }

@media (max-width: 1366px) {
  .ant-layout .side-menu,
  .ant-layout .side-menu + .ant-layout { --width: 225px }
}
@media (max-width: 991px) {
  .ant-layout .side-menu.ant-layout-sider-collapsed,
  .ant-layout .side-menu + .ant-layout,
  .ant-layout .side-menu.ant-layout-sider-collapsed + .ant-layout { --width: 0 }
}


/* Dashboard */
#dashboard .ant-btn svg { width: 20px; height: 20px; }

/* Profile */
.profile-info .name { font-size: 16px; margin-top: 0; margin-bottom: 6px; font-weight: 500; }
.profile-info .job { font-size: 13px; margin-top: 0; margin-bottom: 0; color: rgb(116, 116, 116); font-weight: 400; }
#profile .profile-info { text-align: center; }
.profile-info .profile-img { max-width: 120px; max-height: 120px; border-radius: 50%; overflow: hidden; }
#profile .profile-info .profile-img { margin-bottom: 18px; }
#profile .content-card.skills svg { height: 12px; width: 12px; }

/* Settings */
#settings .ant-radio-button-wrapper-checked { outline: 4px solid #dadada; border: none; }
#settings .content-bx { margin-top: 0; margin-bottom: 24px; }

/* Auth */
#auth { min-height: 100vh; width: 100%; display: grid; align-content: center; padding: 1rem 0; }
#auth .auth-card { width: 475px; max-width: calc(100vw - 2rem); margin: auto; }
#auth .auth-card .ant-card-body { padding: 0; }
#auth .auth-card .o-auth { padding: 25px 25px 0; }
#auth .auth-card .ant-form { padding: 0 25px 25px; }
#auth .auth-card .ant-card-head-title { font-size: 20px; font-weight: 600; text-align: center; }
#auth .auth-card .form-msg { display: flex; flex-wrap: wrap; justify-content: space-between; margin-bottom: 25px; }
#auth .auth-card .form-msg .ant-form-item { margin-bottom: 0; }
#auth .auth-card .form-footer { text-align: center; background-color: rgb(239, 240, 243); padding: 25px; }
#auth .auth-card button { width: 100%; }
#auth .auth-card .social-auth { padding: 0 25px 25px; display: flex; gap: .5rem; flex-wrap: wrap; justify-content: space-between; }
#auth .auth-card .social-auth button { display: flex; width: 30%; }

/* Error Page */
#error-page { min-height: 100vh; width: 100%; display: grid; align-content: center;}
#error-page .content-bx { text-align: center; }
#error-page .content-bx div { min-height: fit-content !important; }