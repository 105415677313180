@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Farsan&display=swap');

#home {
  display: flex;
  flex-direction: column;
  background: rgb(0,157,220);
  background: linear-gradient(187deg, rgba(0,157,220,.4) 0%, rgba(255,255,255,1) 100%);
  min-height: 100vh;
  scroll-behavior: smooth;
}
#home header {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#home button { text-transform: uppercase; font-size: 15px; }

#logo {
  height: 28px;
}
#hero {
  margin-top: 60px;
}
#hero .text-bx{
  text-align: center;
  max-width: 700px;
  margin: auto;
}
#hero h2 {
  font-family: "Syne", sans-serif;
  font-size: 65px;
  line-height: 85px;
  margin-top: 0;
  margin-bottom: 15px;
}
#hero p {
  font-family: "Syne", sans-serif;
  font-size: 24px;
  line-height: 34px;
  margin-top: 0;
  margin-bottom: 25px;  
}
#hero .img-bx {
  position: relative;
  margin: 140px auto 0;
  width: 1100px;
  max-width: 70vw;
}
#hero .img-bx .text{
  position: absolute;
  font-family: "Farsan", cursive;
  font-size: 28px;
  line-height: 36px;
}
#hero .img-bx .text-1{
  top: 30%;
  left: -20%;
  transform: rotate(-15deg);
}
#hero .img-bx .text-2 {
  top: -25%;
  left: 15%;
  transform: rotate(-11deg);
}
#hero .img-bx .text-3{
  top: -20%;
  right: 20%;
  transform: rotate(-15deg);
}
#hero .img-bx .text img{position: absolute;}
#hero .img-bx .text-1 img{
  top: -40%;
  right: -40%;
  transform: rotate(20deg);
}
#hero .img-bx .text-2 img{
  top: 40%;
  right: -90%;
  transform: rotate(-5deg);
}
#hero .img-bx .text-3 img{
  top: 100%;
  right: -10%;
  transform: rotate(15deg);
}
#hero .img-bx > img {
  display: flex;
  margin: auto;
  height: 400px;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

#home footer {
  padding: 34px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  background-color: #F8F9FA;
  margin-top: auto;
}

/* Responsive */
@media (max-width: 1536px) {
  
}